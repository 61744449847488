import { RelayEnvironmentProvider } from 'react-relay';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import { UnsupportedBrowserWarning, isUnSupportedBrowser } from '@woovi/ui';

import LoginProviders from './components/LoginProviders.tsx';
import Environment from './relay/Environment.tsx';
import { routes } from './router/routes/routes.tsx';

import type { ReactNode } from 'react';

const Main = (): ReactNode => {
  if (isUnSupportedBrowser()) {
    return <UnsupportedBrowserWarning />;
  }

  return (
    <RelayEnvironmentProvider environment={Environment}>
      <LoginProviders>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <Switch>{renderRoutes(routes)}</Switch>
          </QueryParamProvider>
        </BrowserRouter>
      </LoginProviders>
    </RelayEnvironmentProvider>
  );
};

export default Main;
