/**
 * @generated SignedSource<<b847e2b51877ad55a138dccd0c8e946a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoginAppQuery$variables = Record<PropertyKey, never>;
export type LoginAppQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LoginApp_query">;
};
export type LoginAppQuery = {
  response: LoginAppQuery$data;
  variables: LoginAppQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginAppQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "LoginApp_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoginAppQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserMaster",
        "kind": "LinkedField",
        "name": "meMaster",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shouldChangePassword",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "requiredFields",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b2af4fc8d1a8924ff79c758b57dd6bc",
    "id": null,
    "metadata": {},
    "name": "LoginAppQuery",
    "operationKind": "query",
    "text": "query LoginAppQuery {\n  ...LoginApp_query\n}\n\nfragment LoginApp_query on Query {\n  meMaster {\n    ...useAuthenticated_userMaster\n    ...useShouldChangePassword_userMaster\n    ...useUserMasterRequiredFields_userMaster\n    id\n  }\n  me {\n    ...useShouldChangePassword_user\n    id\n  }\n}\n\nfragment useAuthenticated_userMaster on UserMaster {\n  id\n}\n\nfragment useShouldChangePassword_user on User {\n  id\n}\n\nfragment useShouldChangePassword_userMaster on UserMaster {\n  shouldChangePassword\n}\n\nfragment useUserMasterRequiredFields_userMaster on UserMaster {\n  requiredFields\n}\n"
  }
};
})();

(node as any).hash = "70a716b419b5d4a6713a956bbaed5a05";

export default node;
