/**
 * @generated SignedSource<<b2a38b7667fcb670892e0d1913b50f83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UserLogoutInput = {
  clientMutationId?: string | null | undefined;
};
export type UserLogoutMutation$variables = {
  input: UserLogoutInput;
};
export type UserLogoutMutation$data = {
  readonly UserLogout: {
    readonly error: string | null | undefined;
  } | null | undefined;
};
export type UserLogoutMutation = {
  response: UserLogoutMutation$data;
  variables: UserLogoutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserLogoutPayload",
    "kind": "LinkedField",
    "name": "UserLogout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserLogoutMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserLogoutMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "54aebdc780daab1c6564745eee51c5fa",
    "id": null,
    "metadata": {},
    "name": "UserLogoutMutation",
    "operationKind": "mutation",
    "text": "mutation UserLogoutMutation(\n  $input: UserLogoutInput!\n) {\n  UserLogout(input: $input) {\n    error\n  }\n}\n"
  }
};
})();

(node as any).hash = "a852830d52310332b4f8fc06ec63dbb4";

export default node;
