import loadable from '@loadable/component';
import { Redirect } from 'react-router';

import Logout from '../../components/logout/Logout.tsx';
import LoginApp from '../../components/root/LoginApp.tsx';

export const routes = [
  {
    name: 'login.root',
    path: '/login',
    component: LoginApp,
    exact: false,
    routes: [
      {
        name: 'login',
        path: '/login',
        exact: true,
        component: loadable(() => import('../../components/login/Login.tsx')),
      },
      {
        name: 'login.validateCode',
        path: '/login/validateCode',
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/login/company/LoginCompanyMagicLinkSuccess.tsx'
            ),
        ),
      },
      {
        name: 'login.company.success',
        path: '/login/company/success',
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/login/company/LoginCompanyMagicLinkSuccess.tsx'
            ),
        ),
      },
      {
        name: 'login.password.forgot',
        path: '/login/password/forgot',
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/login/password/LoginPasswordForgotSuccess.tsx'
            ),
        ),
      },
      {
        name: 'login.changePassword',
        path: '/login/changePassword',
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/login/password/LoginPasswordResetForm.tsx'
            ),
        ),
      },
      {
        name: 'login.withMagicLink',
        path: '/login/magicToken/:token',
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/login/company/LoginCompanyMagicLinkToken.tsx'
            ),
        ),
      },
      {
        name: 'login.user',
        path: '/login/user',
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/login/userMaster/UserMasterPasswordLogin.tsx'
            ),
        ),
      },
      {
        name: 'login.user.multi-factor',
        path: '/login/user/multi-factor',
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/login/userMaster/UserMasterPasswordMultiFactorLogin.tsx'
            ),
        ),
      },
      {
        name: 'login.user.withMagicLink',
        path: '/login/user/magicToken/:token',
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/login/userMaster/UserMasterMagicLinkTokenLogin.tsx'
            ),
        ),
      },
      {
        name: 'login.user.companies',
        path: '/login/user/companies',
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/login/userMaster/UserMasterCompaniesLogin.tsx'
            ),
        ),
      },
      {
        name: 'login.user.company.create',
        path: '/login/user/company/create',
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/login/userMaster/UserMasterCompanyCreate.tsx'
            ),
        ),
      },
      {
        name: 'login.user.company.create.id',
        path: '/login/user/company/create/:id',
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/login/userMaster/UserMasterCompanyCreate.tsx'
            ),
        ),
      },
      {
        name: 'login.user.shouldChangePassword',
        path: '/login/user/should-change-password',
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/login/userMaster/UserMasterShouldChangePassword.tsx'
            ),
        ),
      },
      {
        name: 'login.user.should-confirm-taxid',
        path: `/login/user/should-confirm-taxid`,
        component: loadable(
          () =>
            import(
              '../../components/login/userMaster/UserMasterShouldConfirmTaxID.tsx'
            ),
        ),
        exact: true,
      },
      {
        name: 'login.user.password.forgot',
        path: '/login/user/password/forgot',
        exact: true,
        component: loadable(
          () =>
            import(
              '../../components/login/userMaster/UserMasterPasswordForgotSuccess.tsx'
            ),
        ),
      },
      {
        name: 'logout',
        path: '/login/logout',
        exact: false,
        component: () => <Logout />,
      },
      {
        name: 'redirect',
        path: '/',
        exact: false,
        component: () => <Redirect to='/login' />,
      },
    ],
  },
];
