import { useEffect } from 'react';
import { graphql, readInlineData } from 'react-relay';
import { useHistory } from 'react-router';

import { useQuery } from '@woovi/hooks';

import type { useAuthenticated_userMaster$key } from './__generated__/useAuthenticated_userMaster.graphql.ts';

export const useAuthenticated = (
  userMasterRef: useAuthenticated_userMaster$key,
): boolean => {
  const history = useHistory();
  const { next, ...otherParams } = useQuery();

  const userMaster = readInlineData<useAuthenticated_userMaster$key>(
    graphql`
      fragment useAuthenticated_userMaster on UserMaster @inline {
        id
      }
    `,
    userMasterRef,
  );

  const hasUserMaster = !!userMaster?.id;

  const redirectIfNotAuthenticated = (): void => {
    if (!hasUserMaster && !location.pathname.startsWith('/login')) {
      if (next) {
        window.location.href = `/login?invalidSession=true&next=${next}`;

        return;
      }

      window.location.href = '/login?invalidSession=true';

      return;
    }

    // todo: migrate to have a user master router
    const isLogin =
      location.pathname === '/login/' || location.pathname === '/login';

    if (hasUserMaster && isLogin) {
      if (next) {
        const otherParamsString = new URLSearchParams(otherParams).toString();
        const nextUrl = otherParamsString
          ? `${next}&${otherParamsString}`
          : next;

        return history.push(`/login/user/companies?next=${nextUrl}`);
      }

      return history.push('/login/user/companies');
    }
  };

  useEffect(() => {
    redirectIfNotAuthenticated();
  });

  if (location.pathname.startsWith('/login')) {
    return true;
  }

  return hasUserMaster;
};
