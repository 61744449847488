import type { CacheConfig, UploadableMap, Variables } from 'react-relay';
import type { RequestNode } from 'relay-runtime';
import { QueryResponseCache } from 'relay-runtime';

import { forceFetch, isMutation } from '@woovi/relay';

import { networkFetch } from './networkFetch.ts';

const oneMinute = 60 * 1000;

const relayResponseCache = new QueryResponseCache({
  size: 250,
  ttl: oneMinute,
});

const cacheHandler = async (
  request: RequestNode,
  variables: Variables,
  cacheConfig: CacheConfig,
  uploadables: UploadableMap,
) => {
  const queryID = request.text;

  if (isMutation(request)) {
    relayResponseCache.clear();

    return networkFetch(request, variables, cacheConfig, uploadables);
  }

  const fromCache = relayResponseCache.get(queryID, variables);

  if (fromCache !== null && forceFetch(cacheConfig) === false) {
    return fromCache;
  }

  const fromServer = await networkFetch(
    request,
    variables,
    cacheConfig,
    uploadables,
  );

  if (fromServer) {
    relayResponseCache.set(queryID, variables, fromServer);
  }

  return fromServer;
};

export default cacheHandler;
