import { useScript } from '@woovi/hooks';

import { config } from '../../config.tsx';

const baseUrl = config.CHATWOOT_BASE_URL;

const websiteToken = config.CHATWOOT_WEBSITE_TOKEN;

export const useChatWoot = (): null => {
  const onLoad = (): void => {
    // eslint-disable-next-line
    console.log('Chatwoot loaded');
    if (window.chatwootSDK) {
      window.chatwootSDK.run({
        websiteToken,
        baseUrl,
      });
    }
  };

  const onError = (error: Error): void => {
    // eslint-disable-next-line
    console.log('Chatwoot error: ', {
      error,
      src: `${baseUrl}/packs/js/sdk.js`,
    });
  };

  useScript({
    src: `${baseUrl}/packs/js/sdk.js`,
    onLoad,
    onError,
    checkForExisting: false,
  });

  return null;
};
