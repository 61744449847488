/**
 * @generated SignedSource<<f895e9f33e8e8e5460398094e43b8d8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoginApp_query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"useShouldChangePassword_user">;
  } | null | undefined;
  readonly meMaster: {
    readonly " $fragmentSpreads": FragmentRefs<"useAuthenticated_userMaster" | "useShouldChangePassword_userMaster" | "useUserMasterRequiredFields_userMaster">;
  } | null | undefined;
  readonly " $fragmentType": "LoginApp_query";
};
export type LoginApp_query$key = {
  readonly " $data"?: LoginApp_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"LoginApp_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoginApp_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMaster",
      "kind": "LinkedField",
      "name": "meMaster",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useAuthenticated_userMaster",
          "selections": (v0/*: any*/),
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useShouldChangePassword_userMaster",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shouldChangePassword",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useUserMasterRequiredFields_userMaster",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "requiredFields",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "useShouldChangePassword_user",
          "selections": (v0/*: any*/),
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "ba2d6b8c19564129ae765ac7601e5766";

export default node;
