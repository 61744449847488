import type { ReactNode } from 'react';
import styled from 'styled-components';

const Content = styled.div`
  margin: 16px 8px;

  @media (min-width: 600px) {
    margin: 16px;
  }
`;

type Props = {
  children: ReactNode;
};

export default ({ children }: Props) => {
  return <Content>{children}</Content>;
};
