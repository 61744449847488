/**
 * @generated SignedSource<<d24645454b6d5defc3d00da2d7fcfb35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useAuthenticated_userMaster$data = {
  readonly id: string;
  readonly " $fragmentType": "useAuthenticated_userMaster";
};
export type useAuthenticated_userMaster$key = {
  readonly " $data"?: useAuthenticated_userMaster$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAuthenticated_userMaster">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useAuthenticated_userMaster"
};

(node as any).hash = "0aae6c61906b79067e4bb9e5dfd86569";

export default node;
