import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/600.css';
import '@fontsource/nunito/800.css';

// This is used to add routes to allRoutes (preload this modules)

import App from './App.tsx';
import { appendRoutes } from './router/routes/index.tsx';
import { routes } from './router/routes/routes.tsx';

appendRoutes(routes);

if (process.env.GA_ID) {
  ReactGA.initialize(process.env.GA_ID);
}

const rootEl = document.getElementById('root');

if (rootEl) {
  const root = createRoot(rootEl);

  root.render(<App />);
} else {
  throw new Error('wrong rootEl');
}
