import { StyledEngineProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';

import { withErrorBoundary } from '@woovi/hooks';
import {
  SnackbarProvider as SnackbarProviderUI,
  Loading,
  DialogProvider,
  ThemeProvider,
} from '@woovi/ui';

import i18n from '../i18n/i18n.tsx';

import type { ReactNode } from 'react';

type LoginProvidersProps = {
  children: React.ReactNode;
};

const LoginProviders = ({ children }: LoginProvidersProps): ReactNode => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider>
        <StyledEngineProvider injectFirst>
          <Suspense fallback={<Loading fullScreen />}>
            <I18nextProvider i18n={i18n}>
              <SnackbarProviderUI>
                <SnackbarProvider>
                  <DialogProvider>{children}</DialogProvider>
                </SnackbarProvider>
              </SnackbarProviderUI>
            </I18nextProvider>
          </Suspense>
        </StyledEngineProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default withErrorBoundary(LoginProviders);
