export const config = {
  LOGIN_GRAPHQL_URL: process.env.LOGIN_GRAPHQL_URL as string,
  NODE_ENV: process.env.NODE_ENV as string,
  COMPANY_NAME: process.env.COMPANY_NAME as string,
  COMPANY_EMAIL: process.env.COMPANY_EMAIL as string,
  CRISP_CHAT_ID: process.env.CRISP_CHAT_ID as string,
  CLARITY_ID: process.env.CLARITY_ID as string,
  APP_ENV: process.env.APP_ENV as string,
  GOOGLE_RECAPTCHA_SITE_V3: process.env.GOOGLE_RECAPTCHA_SITE_V3 as string,
  PROJECT_TYPE: process.env.PROJECT_TYPE as string,
  DISABLE_RECAPTCHA: process.env.DISABLE_RECAPTCHA as string,
  ENTRYPOINT: process.env.ENTRYPOINT,
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
  CHATWOOT_BASE_URL: process.env.CHATWOOT_BASE_URL,
  CHATWOOT_WEBSITE_TOKEN: process.env.CHATWOOT_WEBSITE_TOKEN,
} as const;
