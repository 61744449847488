/**
 * @generated SignedSource<<9a24093bf2c9c4a80e374205ae2eff40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useShouldChangePassword_user$data = {
  readonly id: string;
  readonly " $fragmentType": "useShouldChangePassword_user";
};
export type useShouldChangePassword_user$key = {
  readonly " $data"?: useShouldChangePassword_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useShouldChangePassword_user">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useShouldChangePassword_user"
};

(node as any).hash = "29d9cbaf7a687b37610641cb9e5c7902";

export default node;
