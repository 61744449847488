import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en, es, ptBR } from '@woovi/i18n';

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translation: en,
    },
    'pt-BR': {
      translation: ptBR,
    },
    es: {
      translation: es,
    },
  },
  fallbackLng: 'pt-BR',
  debug: false,
  nsSeparator: false,
  keySeparator: false,

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    useSuspense: true,
  },
});

export default i18n;
