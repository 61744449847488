import { useEffect } from 'react';
import { graphql, readInlineData } from 'react-relay';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@woovi/hooks';

import type { useShouldChangePassword_user$key } from './__generated__/useShouldChangePassword_user.graphql.ts';
import type { useShouldChangePassword_userMaster$key } from './__generated__/useShouldChangePassword_userMaster.graphql.ts';

export const useShouldChangePassword = (
  userMasterRef: useShouldChangePassword_userMaster$key,
  userRef: useShouldChangePassword_user$key,
) => {
  const history = useHistory();
  const { next } = useQuery();

  const userMaster = readInlineData<useShouldChangePassword_userMaster$key>(
    graphql`
      fragment useShouldChangePassword_userMaster on UserMaster @inline {
        shouldChangePassword
      }
    `,
    userMasterRef,
  );

  const user = readInlineData<useShouldChangePassword_user$key>(
    graphql`
      fragment useShouldChangePassword_user on User @inline {
        id
      }
    `,
    userRef,
  );

  const shouldChangePassword = userMaster?.shouldChangePassword;

  useEffect(() => {
    if (shouldChangePassword) {
      const getNext = () => {
        if (next) {
          return `?next=${next}`;
        }

        if (user?.id) {
          return `?next=/home/start`;
        }

        return ``;
      };

      history.push(`/login/user/should-change-password${getNext()}`);
    }
  }, [userMaster, user]);

  return shouldChangePassword;
};
