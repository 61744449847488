/**
 * @generated SignedSource<<830c9ebaf8015d59f14f108f30716cc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useUserMasterRequiredFields_userMaster$data = {
  readonly requiredFields: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly " $fragmentType": "useUserMasterRequiredFields_userMaster";
};
export type useUserMasterRequiredFields_userMaster$key = {
  readonly " $data"?: useUserMasterRequiredFields_userMaster$data;
  readonly " $fragmentSpreads": FragmentRefs<"useUserMasterRequiredFields_userMaster">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useUserMasterRequiredFields_userMaster"
};

(node as any).hash = "bec47f5600a5e377a81b2921cdd34a0a";

export default node;
