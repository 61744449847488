import { useEffect } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import type { RouteConfigComponentProps } from 'react-router-config';
import { renderRoutes } from 'react-router-config';

import { useClarity } from '@woovi/clarity';
import { usePageView } from '@woovi/hooks';
import { withRelayBoundary } from '@woovi/relay';
import type { FetchKeyProp } from '@woovi/relay';

import type { LoginApp_query$key } from './__generated__/LoginApp_query.graphql.ts';
import type { LoginAppQuery } from './__generated__/LoginAppQuery.graphql.ts';
import { useChatWoot } from './useChatWoot.tsx';
import { config } from '../../config.tsx';
import i18n from '../../i18n/i18n.tsx';
import { useAuthenticated } from '../../router/middlewares/useAuthenticated.tsx';
import { useShouldChangePassword } from '../../router/middlewares/useShouldChangePassword.tsx';
import { useUserMasterRequiredFields } from '../../router/middlewares/useUserMasterRequiredFields.tsx';

type LoginAppProps = RouteConfigComponentProps & FetchKeyProp;

const LoginApp = ({ route, fetchKey }: LoginAppProps) => {
  const response = useLazyLoadQuery<LoginAppQuery>(
    graphql`
      query LoginAppQuery {
        ...LoginApp_query
      }
    `,
    {},
    {
      fetchKey,
      fetchPolicy: 'network-only',
    },
  );

  const query = useFragment<LoginApp_query$key>(
    graphql`
      fragment LoginApp_query on Query {
        meMaster {
          ...useAuthenticated_userMaster
          ...useShouldChangePassword_userMaster
          ...useUserMasterRequiredFields_userMaster
        }
        me {
          ...useShouldChangePassword_user
        }
      }
    `,
    response,
  );

  const { meMaster, me } = query;

  useAuthenticated(meMaster);
  useUserMasterRequiredFields(meMaster);
  useShouldChangePassword(meMaster, me);

  useEffect(() => {
    const lang = meMaster?.lang || 'pt-BR';

    const changeLanguage = async () => {
      await i18n.changeLanguage(lang);
    };

    changeLanguage();
  }, [meMaster]);

  usePageView();
  useClarity({
    clarityId: config.CLARITY_ID,
  });
  // only chatwoot on login for now
  // useCrisp();
  useChatWoot();

  return renderRoutes(route.routes);
};

export default withRelayBoundary(LoginApp);
