import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationCallbacks } from '@woovi/relay';
import { Content } from '@woovi/ui';

import type { UserLogoutMutation } from './__generated__/UserLogoutMutation.graphql.ts';
import { UserLogout } from './UserLogoutMutation.tsx';

import type { ReactNode } from 'react';

const Logout = (): ReactNode => {
  const { t } = useTranslation();

  const [userLogout] = useMutationCallbacks<UserLogoutMutation>({
    name: 'UserLogout',
    mutation: UserLogout,
    shouldShowSnackbar: false,
    afterCompleted: ({ UserLogout }) => {
      if (!UserLogout || UserLogout.error) {
        // eslint-disable-next-line
        console.log('UserLogoutError: ', UserLogout?.error);
      }

      window.location.href = `/login`;
    },
    afterError: (error) => {
      // eslint-disable-next-line
      console.log('UserLogoutError: ', error);

      window.location.href = `/login`;
    },
  });

  const handleLogout = () => {
    if (window.$crisp) {
      window.$crisp.push(['do', 'session:reset']);
    }

    userLogout({ variables: { input: {} } });
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <Content>
      <Typography>{t('Logging out...')}</Typography>
    </Content>
  );
};

export default Logout;
