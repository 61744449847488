import { useEffect } from 'react';
import { graphql, readInlineData } from 'react-relay';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@woovi/hooks';
import { USER_MASTER_REQUIRED_FIELDS_ENUM } from '@woovi/shared';

import type { useUserMasterRequiredFields_userMaster$key } from './__generated__/useUserMasterRequiredFields_userMaster.graphql.ts';

export const useUserMasterRequiredFields = (
  userMasterRef: useUserMasterRequiredFields_userMaster$key,
) => {
  const history = useHistory();
  const { next } = useQuery();

  const userMaster = readInlineData<useUserMasterRequiredFields_userMaster$key>(
    graphql`
      fragment useUserMasterRequiredFields_userMaster on UserMaster @inline {
        requiredFields
      }
    `,
    userMasterRef,
  );

  const shouldChangeTaxID = userMaster
    ? userMaster.requiredFields?.includes(
        USER_MASTER_REQUIRED_FIELDS_ENUM.TAXID,
      )
    : false;

  useEffect(() => {
    if (shouldChangeTaxID) {
      const getNext = () => {
        if (next) {
          return `?next=${next}`;
        }

        return ``;
      };

      history.push(`/login/user/should-confirm-taxid${getNext()}`);
    }
  }, [shouldChangeTaxID]);

  return shouldChangeTaxID;
};
